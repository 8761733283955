import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLink } from "@fortawesome/free-solid-svg-icons";
export default function ContactMe() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_4rg8qlm", "template_jdm6kjb", form.current, {
        publicKey: "j9s2qyEAWB4PUSqvB",
      })
      .then(
        () => {
          alert("Email sent! ");
        },
        (error) => {
          alert("sent FAILED...", error.text);
        }
      );
    if (form.current) {
      form.current.reset();
    }
  };
  return (
    <div className="container mt-5 about-me-heading" id="Contact">
      <p className="section--title text-secondary">
        Contact <br />
        <span className="text-dark"> Me</span>
      </p>
      <div className="row justify-content-center  mb-5 mt-5">
        <div className="col-lg-4 col-md-12 text-center ">
          <img
            src="./images/connect.png"
            className="card-img-top3"
            alt="GitHub Projects"
          />
          <h2 className="card-title mb-3">Connect with Me on LinkedIn! </h2>
          <div className="skills-table text-secondary">
            <div className="skills-cell">
              <a
                href="https://www.linkedin.com/in/rantejbir-singh/"
                className="linkWeb text-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                Rantejbir's LinkedIn Profile{" "}
                <FontAwesomeIcon icon={faLink} style={{ marginRight: "5px" }} />
              </a>
            </div>
          </div>
          <p className="education-desc text-secondary mt-2 mb-5">
            Let's stay connected on LinkedIn! I'm enthusiastic about forging new
            professional connections, Feel free to connect, and let's embark on
            this journey together!
          </p>
        </div>
        <div className="col-2"></div>
        <div class="col-lg-6 col-md-12 education-box1 bg-white text-dark">
          <div className="text-center">
            <img
              src="./images/contact.png"
              className="card-img-top4 text-center"
              alt="GitHub Projects"
            />
          </div>
          <h4 className="text-center ">
            rantejbirs@gmail.com{" "}
            <FontAwesomeIcon
              icon={faEnvelope}
              style={{ color: "#00000", marginRight: "5px" }}
            />
          </h4>
          <form ref={form} onSubmit={sendEmail} class="mt-md-0">
            <div className="row">
              <div class="form-group mt-3 col-6">
                <label>Your Name</label>
                <input type="text" name="user_name" class="form-control" />
              </div>
              <div class="form-group mt-3 col-6">
                <label>Your Email</label>
                <input type="email" name="user_email" class="form-control" />
              </div>
            </div>

            <div class="form-group mt-3">
              <label>Message</label>
              <textarea
                name="message"
                className="form-control"
                id="message"
                rows="2"
              />
            </div>
            <div className="form-group mt-3">
              <input type="submit" value="Send" className="btn btn-primary" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
