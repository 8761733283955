import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
function HeroSection() {
  const [isVisible, setIsVisible] = useState(false);
  const downloadFile = () => {
    const fileUrl = "./images/Rantejbir Singh resume.pdf";
    const a = document.createElement("a");
    a.href = fileUrl;
    a.download = fileUrl;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(true);
    }, 20000);
    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <section id="heroSection" className="hero--section">
      <div className="hero--section--content-box">
        <div className="hero--section--content">
          <p
            className={`section--title text-secondary ${
              isVisible ? "visible" : ""
            }`}
          >
            Hey, I'm <br />
            <span className="text-danger">Rantejbir Singh</span>
          </p>

          <h6 className={`hero--section--title1 ${isVisible ? "visible" : ""}`}>
            Committed to staying on the cutting edge of technology trends, I am
            eager to bring my creative problem-solving skills and hands-on
            experience to drive excellence in every aspect of software
            development.
          </h6>
          <div
            className={`hero--section--social-links ${
              isVisible ? "visible" : ""
            }`}
          >
            <a
              href="https://www.linkedin.com/in/rantejbir-singh/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faLinkedin}
                size="2xl"
                style={{ color: "#0061ff", marginRight: "10px" }}
              />
            </a>
            <a
              href="https://github.com/rantejbir"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faGithub}
                size="2xl"
                style={{ color: "#000000", marginLeft: "10px" }}
              />
            </a>
          </div>
          <button className=" btn  btn-secondary" onClick={downloadFile}>
            Download CV
          </button>
        </div>
      </div>
      <div className={`hero--section--img ${isVisible ? "visible" : ""}`}>
        <img src="./images/Rantejbir Singh (2).png" alt="Hero Section" />
      </div>
    </section>
  );
}
export default HeroSection;
