import React from "react";
import { ReactComponent as WorkIcon } from "../../data/work.svg";
import { ReactComponent as SchoolIcon } from "../../data/school.svg";
import timelineElements from "../../data/index.json";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
const Education = () => {
  let workIconStyles = { background: "#06D6A0" };
  let schoolIconStyles = { background: "#f9c74f" };

  return (
    <div className="container mt-5 about-me-heading " id="Education">
      <p className="  section--title text-gray ">
        Education <br />{" "}
        <span className="text-secondary">
          {" "}
          And <br />
        </span>
        <span className="text-dark"> Experience</span>
      </p>
      <VerticalTimeline>
        {timelineElements.timelineElements.map((element) => {
          let isWorkIcon = element.icon === "work";
          return (
            <VerticalTimelineElement
              key={element.key}
              date={element.date}
              dateClassName="date"
              iconStyle={isWorkIcon ? workIconStyles : schoolIconStyles}
              icon={isWorkIcon ? <WorkIcon /> : <SchoolIcon />}
            >
              <h3 className="vertical-timeline-element-title">
                {element.title}
              </h3>
              <h5 className="vertical-timeline-element-subtitle text-secondary">
                {element.Company}
              </h5>
              <p id="description" className="text-secondary">
                {element.description}
              </p>
            </VerticalTimelineElement>
          );
        })}
      </VerticalTimeline>
    </div>
  );
};

export default Education;
