import React from "react";
import project from "../../data/index.json";
const MySkills = () => {
  return (
    <div
      className="skill-section container justify-content-center mt-4"
      id="Skills"
    >
      <p className="  section--title text-gray about-me-heading">
        Skills <br />{" "}
        <span className="text-secondary">
          {" "}
          And <br />
        </span>
        <span className="text-dark"> Expertise</span>
      </p>
      <div className="row skills-content-main mt-5">
        {project.skills.map((skill) => (
          <div key={skill.id} className="col-lg-6 col-md-12 ">
            <img
              src={skill.image}
              className="card-img-top2"
              alt="Skill Category"
            />

            <div className="education-box ">
              <h3 className="card-title">{skill.category}</h3>
              <div className="skills-table text-secondary">
                {skill.title.split(",").map((item, index) => (
                  <div key={index} className="skills-cell">
                    {item.trim()}
                  </div>
                ))}
                <p className="skills-desc">{skill.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MySkills;
