import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import skills from "../../data/index.json";
const redirectToLeetcode = () => {
  const leetcodeURL = "https://leetcode.com/rantejbirsingh/";
  window.open(leetcodeURL, "_blank");
};
export default function MyPortfolio() {
  return (
    <div className="container" id="portfolio">
      <div className="text-gray">
        <p className="section--title  about-me-heading">
          Android <br /> <span className="text-secondary"> Websites</span>
          <span className="text-dark">
            <br />
            Applications
          </span>
        </p>
      </div>
      <div className="justify-content-center text-center">
        <div className="row portfolio-card-1">
          {skills.project.map((project) => (
            <div key={project.id} className="col-lg-6 col-md-12 mt-4">
              <div className="education-box">
                <h3 className="card-title text-center ">
                  <a
                    href={project.link}
                    className="linkWeb text-success"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {project.title}
                  </a>
                </h3>
                <div className="skills-table text-secondary">
                  {project.technologies.map((tech, index) => (
                    <div key={index} className="skills-cell">
                      {tech}
                    </div>
                  ))}
                  <p className="skills-desc">{project.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row justify-content-center mt-5 education-box1">
          <div className="col-lg-4 col-md-4 mt-5">
            <img
              src="./images/leetcode.png"
              className="card-img-top3"
              alt="LinkedIn Portfolio"
            />
          </div>
          <div className="col-lg-8 col-md-8 mt-4 ">
            <div className=" education-box " onClick={redirectToLeetcode}>
              <h3 className="card-title text-center ">
                Data Structures And Algoritms
              </h3>
              <div className="skills-table text-secondary ">
                <div className="skills-cell linkWeb">
                  Leetcode{" "}
                  <FontAwesomeIcon
                    icon={faLink}
                    style={{ color: "#008000", marginRight: "5px" }}
                  />
                </div>

                <p className="skills-desc mb-5">
                  Embark on a journey through my LeetCode profile to witness my
                  commitment to mastering Data Structures and Algorithms. Dive
                  into a world of problem-solving, optimized solutions, and
                  continuous learning.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
