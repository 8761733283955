import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapLocation } from "@fortawesome/free-solid-svg-icons";
const AboutMe = () => {
  return (
    <div className="about-me-heading " id="AboutMe">
      <div className="container">
        <p className="  section--title text-secondary">
          About
          <span className="text-dark"> Me</span>
        </p>
        <p className="my-desc text-center text-dark ">
          Hello, I'm Rantejbir Singh, originally from Amritsar, India, and
          currently residing in Ottawa for the past two years. I recently
          completed my diploma in Computer Programming from Algonquin College in
          August 2023. My professional journey includes a valuable experience as
          a Backend Developer Intern at The Big Leaf Canada.
          <br />
          <span className="text-secondary">
            Presently, I'm serving as a Security Officer and actively seeking an
            entry-level position in the IT field. Passionate about technology, I
            am eager to contribute my skills and knowledge to further my career
            in the dynamic world of Information Technology.
          </span>
        </p>
        <h4 className="text-center text-primary ">
          <FontAwesomeIcon
            icon={faMapLocation}
            style={{ color: "#008000", marginRight: "5px" }}
          />{" "}
          Ottawa, Canada
        </h4>
      </div>
    </div>
  );
};
export default AboutMe;
